import bitcoinIcon from "@/assets/otcpage/bitcoin.svg";
import usdIcon from "@/assets/otcpage/usd-icon.svg";
import tetherIcon from "@/assets/otcpage/tether-icon.svg";
import fantomIcon from "@/assets/otcpage/fantom-icon.svg";
import ethereumIcon from "@/assets/otcpage/etherum-icon.svg";

export const TOKEN_ICONS = {
  BTC: bitcoinIcon.src,
  USD: usdIcon.src,
  USDT: tetherIcon.src,
  ETH: ethereumIcon.src,
  FTM: fantomIcon.src,
};

export async function getCurrentAccountIdFromCookies() {
  const cookie = await window.cookieStore.get("accountId");
  return cookie?.value;
}